import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  PopconfirmProps,
  Select,
  Table,
  Tabs,
  Tag,
} from "antd";

import { useAppSelector } from "../../../store/hooks";

import DeleteModal from "../../../components/Modals/DeleteModal";
import CreateCategory from "../../../components/Modals/CreateCategory";

import {
  deleteSubCategory,
  getAllCategory,
  addSubCategory,
} from "../../../store/services/category";
import { CategoryType } from "../../../store/interfaces/Category";
import { firstLetterCap } from "../../../utils";
import { RxCrossCircled } from "react-icons/rx";
import { IoIosAddCircle } from "react-icons/io";
import moment from "moment";
import { getCategoriesByType } from "../../../store/slices/categorySlice";

const { Search } = Input;

const Category: React.FC = () => {
  const { isLoading, allCategories, typeCategories } = useAppSelector(
    (state) => state.category
  );
  // const { typeCategories } = useAppSelector((state) => state.category);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [categoryData, setCategoryData] = useState<Array<CategoryType>>([]);
  const [subcategoryData, setSubcategoryData] = useState<Array<CategoryType>>(
    []
  );
  const [activeTab, setActiveTab] = useState("1");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<string>("");
  const [checkCat, setCheckCat] = useState<any>();
  const [form] = Form.useForm();
  const getData = async () => {
    await getAllCategory();
  };


  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setCategoryData(
      allCategories
        ?.filter((v) => v?.categoryType === "category")
        ?.sort((a, b) => a.sortNumber - b.sortNumber)
    );
    setSubcategoryData(
      allCategories
        ?.filter((v) => v?.categoryType === "subcategory")
        ?.sort((a, b) => a.sortNumber - b.sortNumber)
    );
  }, [allCategories]);

  const imageColumn = {
    title: " ",
    dataIndex: "imageUrl",
    key: "imageUrl",
    render: (t: string) => (
      <div
        className="users_image_box"
        style={{
          backgroundImage: `url(${
            t ? t : require("../../../assets/images/cart.png")
          })`,
        }}
      ></div>
    ),
  };
  const nameColumn = {
    title: "Name",
    dataIndex: "name",
    key: "name",
  };
  const dateColumn = {
    title: "Created At",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (t: string) => <p>{moment(t).format("MMMM DD, YYYY") || "-"} </p>,
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onFinish = async (value: any) => {

    setLoading("submit");
    const payload = {
      _id: value.categoryId,
      categoryId: checkCat._id,
    };

    const res = await addSubCategory(payload);

    if (res) getData();
    form.resetFields();
    setLoading("");
    setOpen(false);
  };
  const addCategory = (obj: any) => {
    // _getCategoriesByType();
    setOpen(true);
    setCheckCat(obj);
  };

  const deleteCategory = async (cat: any, obj: any) => {
    
    const payload = {
      _id: cat._id,
      categoryId: obj._id,
    };

    const res = await deleteSubCategory(payload);
   
    if (res) getData();
  };

  const mainCategoryColumn = {
    title: "Main Category",
    dataIndex: "categoryId",
    key: "categoryId",
    render: (_: string, obj: any) => {
      return (
        <p>
          {obj?.categoryId?.length
            ? obj?.categoryId &&
              obj?.categoryId?.map((category: CategoryType) => (
                <>
                  <Tag color="blue" style={{ position: "relative" }} icon={ <Popconfirm
                      title="Delete the Category"
                      description="Are you sure to delete this ?"
                      onConfirm={() => deleteCategory(category, obj)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <RxCrossCircled
                        style={{
                          // position: "absolute",
                          // right: "-5px",
                          // top: "-5px",
                          marginTop:'5px',
                          marginRight:"5px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    </Popconfirm>}>
             
                    {firstLetterCap(category?.name)}
                  </Tag>
                </>
              ))
            : "-"}
          <IoIosAddCircle
            style={{
              marginLeft: "5px",
              cursor: "pointer",
            }}
            onClick={() => addCategory(obj)}
          />
        </p>
      );
    },
  };
  const actionscolumn = {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_: string, obj: CategoryType) => {
      return (
        <div className="icon_box">
          <CreateCategory
            getData={getData}
            isEdit
            editObj={obj}
            isMainCategory={activeTab === "1"}
          />
          <DeleteModal type={"category"} id={obj?._id} getData={getData} />
        </div>
      );
    },
  };
  const categoryColumns = [imageColumn, dateColumn, nameColumn, actionscolumn];
  const subcategoryColumns = [
    imageColumn,
    dateColumn,
    nameColumn,
    mainCategoryColumn,
    actionscolumn,
  ];

  const filteredCategory = categoryData?.filter((v) =>
    v?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );
  const filteredSubcategory = subcategoryData?.filter(
    (v) =>
      v?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
      v?.categoryId?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const tabItems = [
    {
      label: "Main Category",
      key: "1",
      children: (
        <div
          className="user_table_container category_table"
          style={{ marginTop: 0 }}
        >
          <Table
            loading={isLoading}
            rowKey={(record) => record._id}
            columns={categoryColumns}
            dataSource={filteredCategory}
            scroll={{ x: 600 }}
            pagination={{
              current: page,
              total: filteredCategory?.length,
              pageSize: perPage,
              onChange: (val) => {
                setPage(val);
              },
            }}
          />
        </div>
      ),
    },
    {
      label: "Sub Category",
      key: "2",
      children: (
        <div
          className="user_table_container category_table"
          style={{ marginTop: 0 }}
        >
          <Table
            loading={isLoading}
            rowKey={(record) => record._id}
            columns={subcategoryColumns}
            dataSource={filteredSubcategory}
            scroll={{ x: 600 }}
            pagination={{
              current: page,
              total: filteredSubcategory?.length,
              pageSize: perPage,
              onChange: (val) => {
                setPage(val);
              },
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="user_container">
      <header>
        <h1>Categories</h1>
        <CreateCategory getData={getData} isMainCategory={activeTab === "1"} />
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search by name"
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div style={{ marginTop: "30px" }}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"small"}
          items={tabItems}
          onChange={(key) => {
            setPage(1);
            setActiveTab(key);
            setSearch("");
          }}
        />
      </div>

      <Modal
        title="Select Category"
        open={open}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form form={form} onFinish={onFinish}>
          <Form.Item name={"categoryId"} label="Main Category">
            <Select
              mode="multiple"
              loading={loading === "dropdown"}
              placeholder="Select Category"
              className="users_select_box"
              showSearch
              optionFilterProp="children"
              filterOption={(
                input: string,
                option?: { label: string; value: string }
              ) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={categoryData
                ?.filter(
                  (v) =>
                    !checkCat?.categoryId?.some((cat: any) => cat._id === v._id)
                )
                ?.map((v) => ({
                  value: v?._id,
                  label: v?.name,
                }))}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleCancel}>
              Cancel
            </Button>
            &nbsp; &nbsp;
            <Button
              loading={loading === "submit"}
              type="primary"
              htmlType="submit"
            >
              {"Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Category;
