import { configureStore } from "@reduxjs/toolkit";

import themeReducer from "./slices/themeSlice";
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import productReducer from "./slices/productSlice";
import cartReducer from "./slices/cartSlice";
import wishlistReducer from "./slices/wishlistSlice";
import dashboardReducer from "./slices/dashboardSlice";
import discountReducer from "./slices/discountSlice";
import orderReducer from "./slices/orderSlice";
import cateogryReducer from "./slices/categorySlice";
import rewardReducer from "./slices/rewardSlice";
import scheduleSlice from "./slices/scheduleSlice";
import reviewSlice from "./slices/reviewSlice";
import settingsSlice from "./slices/settingsSlice";
import groupWishlistSlice from "./slices/groupWishlistSlice";
import labelSlice from "./slices/labelSlice";
import badgeSlice from "./slices/badgeSlice";
import notificationSettingsSlice from "./slices/notificationSettingsSlice";
import socketSlice from "./slices/socketSlice";
import notificationSlice from "./slices/notificationSlice";
import  carouselSlice  from "./slices/carouselSlice"
const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    user: userReducer,
    product: productReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    dashboard: dashboardReducer,
    discount: discountReducer,
    order: orderReducer,
    category: cateogryReducer,
    reward: rewardReducer,
    schedule: scheduleSlice,
    review: reviewSlice,
    settings: settingsSlice,
    groupWishlist: groupWishlistSlice,
    label: labelSlice,
    badge: badgeSlice,
    notificationsSettings: notificationSettingsSlice,
    notifications: notificationSlice,
    socket: socketSlice,
    carousel: carouselSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
