import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";
import { setCarousel, setCarouselLoading } from "../slices/carouselSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Deleted = "Carousel Deleted Successfully";

export const createCarousel = async (payload: any) => {
  try {
    attachToken();
    store.dispatch(setCarouselLoading(true));
    const response = await privateAPI.post(
      "/dashboardCaresoul/create",
      payload
    );
    if(response){
      notification.success({
        message: response?.data?.data?.message || "create Carousel Successfully",
      });
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setCarouselLoading(false));
  }
};
export const getCarousel = async () => {
  try {
    attachToken();
    store.dispatch(setCarouselLoading(true));
    const response = await privateAPI.get("/dashboardCaresoul/get");
    if (response) {
      store.dispatch(
        setCarousel(response?.data?.data?.dashbaordcaresoulproduct)
      );
    }
    return response?.data?.data?.dashbaordcaresoulproduct;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setCarouselLoading(false));
  }
};
export const updateCarousel = async (payload: any) => {
    try {
      attachToken();
      store.dispatch(setCarouselLoading(true));
      const response = await privateAPI.post(
        "/dashboardCaresoul/update",
        payload
      );
      if(response){
        notification.success({
          message: response?.data?.data?.message || "update Carousel Successfully",
        });
      }
      return response?.data;
    } catch (error: any) {
      handleError(error);
    } finally {
      store.dispatch(setCarouselLoading(false));
    }
  };
export const deleteCarousel = async (payload: any) => {
    try {
      attachToken();
      store.dispatch(setCarouselLoading(true));
      const response = await privateAPI.post(
        "/dashboardCaresoul/delete",
        payload
      );
      if(response){
        notification.success({
          message: response?.data?.data?.message || "Delete Carousel Successfully",
        });
      }
      return response?.data;
    } catch (error: any) {
      handleError(error);
    } finally {
      store.dispatch(setCarouselLoading(false));
    }
  };