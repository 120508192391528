import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  Col,
  Card,
  Carousel,
  message,
  Spin,
} from "antd";
import {
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useAppSelector } from "../../../store/hooks";
import { getAllProducts } from "../../../store/services/product";
import ProductCard from "../../../components/ProductCard";
import {
  createCarousel,
  deleteCarousel,
  getCarousel,
  updateCarousel,
} from "../../../store/services/caruoselAdd";

const EditCarousel = () => {
  const [carousels, setCarousels] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [previewProducts, setPreviewProducts] = useState<any>([]);
  const [editingCarousel, setEditingCarousel] = useState(false);
  const [editId, setEditId] = useState("");
  const { allProducts } = useAppSelector((state) => state.product);
  const { carousel, carouselLoading } = useAppSelector(
    (state) => state.carousel
  );
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    getData();
    getAllCarousel();
  }, []);

  const getData = async () => {
    await getAllProducts();
  };

  const getAllCarousel = async () => {
    const res = await getCarousel();
    setCarousels(res);
  };

  const getProductDetails = (productId: string) => {
    return allProducts.find((product: any) => product._id === productId);
  };

  const handleProductSelect = (selectedProductIds: string[]) => {
    const selectedProducts = allProducts.filter((product: any) =>
      selectedProductIds.includes(product._id)
    );
    setPreviewProducts(selectedProducts);
  };

  const handleEdit = async (carousel: any) => {
    setEditingCarousel(true);
    setEditId(carousel._id);
    form.setFieldsValue({
      title: carousel.title,
      products: carousel.products.map((p: any) => p._id),
      sort: carousel.sort,
      _id: carousel._id,
    });
    setPreviewProducts(carousel.products);

    setIsModalVisible(true);
  };

  const handleDelete = async (carousel: any) => {
    const res = await deleteCarousel({ _id: carousel._id });
    if (res) getAllCarousel();
  };

  const handleSubmit = async (values: any) => {
    try {
      // Save carousel with IDs and full product data
      setIsSaving(true);
      const carouselData = {
        title: values.title,
        products: values.products,
        sort: values.sort,
        _id: editId,
      };

      const res = editingCarousel
        ? await updateCarousel(carouselData)
        : await createCarousel(values);
      if (res) {
        getAllCarousel();
        setIsModalVisible(false);
        setEditId("");
        form.resetFields();
        setPreviewProducts([]);
        setEditingCarousel(false);
        setIsSaving(false);
      }
    } catch (error) {
      setIsSaving(false);
      console.error("Error:", error);
      message.error("Failed to save carousels");
    }
  };

  const CustomArrow = ({
    type,
    onClick,
  }: {
    type: "prev" | "next";
    onClick?: () => void;
  }) => (
    <Button
      icon={type === "prev" ? <LeftOutlined /> : <RightOutlined />}
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 2,
        ...(type === "prev" ? { left: -25 } : { right: -25 }),
      }}
    />
  );

  return (
    <div style={{ padding: "20px" }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setIsModalVisible(true)}
        style={{ marginBottom: 24 }}
      >
        Add New Carousel
      </Button>

      {carouselLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {carousels?.map((carousel: any, index: number) => (
            <div key={index} style={{ marginBottom: 20, position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  zIndex: 3,
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(carousel)}
                  type="primary"
                  ghost
                />
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(carousel)}
                  danger
                />
              </div>

              <h2>{carousel.title}</h2>
              <Carousel
                arrows
                prevArrow={<CustomArrow type="prev" />}
                nextArrow={<CustomArrow type="next" />}
              >
                {chunk(carousel.products, 4).map(
                  (group: any, groupIndex: number) => (
                    <div key={groupIndex}>
                      <Row gutter={[20, 20]}>
                        {group.map((product: any, i: number) => (
                          <Col
                            key={product._id + i}
                            xs={24}
                            sm={12}
                            lg={8}
                            xl={6}
                            xxl={6}
                          >
                            <ProductCard data={product} />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )
                )}
              </Carousel>
            </div>
          ))}
        </>
      )}
      {/* Add/Edit Modal */}
      <Modal
        title={editingCarousel ? "Edit Carousel" : "Create New Carousel"}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
          setPreviewProducts([]);
          setEditingCarousel(false);
        }}
        footer={null}
        width={1000}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Carousel Title"
                rules={[{ required: true }]}
              >
                <Input placeholder="e.g., Weekly Best Sellers" />
              </Form.Item>
              <Form.Item name="sort" label="Sort " rules={[{ required: true }]}>
                <Input placeholder="number.." type="number" />
              </Form.Item>
              <Form.Item
                name="products"
                label="Select Products"
                rules={[{ required: true }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Search and select products"
                  optionFilterProp="children"
                  onChange={handleProductSelect}
                  style={{ width: "100%" }}
                  showSearch
                  maxTagCount="responsive" // Yeh selected items ko wrap karega
                  dropdownRender={(menu) => (
                    <div style={{ maxHeight: 250, overflowY: "auto" }}>
                      {menu}
                    </div>
                  )}
                >
                  {allProducts?.map((product) => (
                    <Select.Option key={product._id} value={product._id}>
                      {product.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <div style={{ marginBottom: 16 }}>
                <h3>Preview</h3>
                <div className="carousel-preview">
                  <Row
                    gutter={[16, 16]}
                    wrap={false}
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {previewProducts
                      .slice(0, form.getFieldValue("productsPerRow"))
                      .map((product: any) => (
                        <Col
                          key={product._id}
                          span={
                            24 / (form.getFieldValue("productsPerRow") || 4)
                          }
                        >
                          <Card
                            hoverable
                            cover={
                              <img
                                alt={product.name}
                                src={product.imagesUrl?.[0]}
                                style={{ height: 120, objectFit: "cover" }}
                              />
                            }
                            size="small"
                          >
                            <Card.Meta title={product.name} />
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Form.Item style={{ marginTop: 16, textAlign: "right" }}>
            <Button type="primary" htmlType="submit" loading={isSaving}>
              {editingCarousel ? "Update Carousel" : "Create Carousel"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

// Helper function to split array into chunks
const chunk = (array: any[], size: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

export default EditCarousel;
