import React, { useState } from "react";

import { Modal, Button, notification } from "antd";

import { MdOutlineDone } from "react-icons/md";
import { updateOrderStatus } from "../../store/services/order";

interface Props {
  id: string;
  status: string;
  isClick?: boolean;
}

const Status = ({ id, status, isClick = true }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");

  const normalizedStatus = status === "Completed" ? "Approved" : status;
  console.log("🚀 ~ normalizedStatus:", normalizedStatus);

  const showModal = () => {
    if (!isClick) return;
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelected("");
  };

  const handleUpdate = async () => {
    if (
      ["Pending", "Approved", "Dispatched"].includes(normalizedStatus) &&
      selected !== ""
    ) {
      setIsLoading(true);
      const res = await updateOrderStatus({
        _id: id,
        orderStatus: selected === "Approved" ? "Completed" : selected,
      });
      if (res) {
        handleCancel();
      }
      console.log("check order >>", { _id: id, orderStatus: selected });
      setIsLoading(false);
    }
  };

  const showError = () => {
    if (!isClick) return;
    notification.error({
      message: `${normalizedStatus} order cannot be changed.`,
    });
  };

  const getColor = (key: string) => {
    let color: { [key: string]: string } = {
      Pending: "lightOrange",
      Dispatched: "lightGreen",
      Completed: "lightBlue",
      Approved: "lightBlue",
      Cancelled: "lightRed",
    };
    return color[key];
  };

  return (
    <>
      {["Pending", "Approved"].includes(normalizedStatus) ? (
        <div
          onClick={showModal}
          className={`status_box ${getColor(normalizedStatus)}`}
        >
          <span>{normalizedStatus}</span>
        </div>
      ) : (
        <div
          onClick={showError}
          className={`status_box ${getColor(normalizedStatus)}`}
        >
          <span>{normalizedStatus}</span>
        </div>
      )}
      <Modal
        forceRender
        title={"Update Status"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="status_modal"
        centered
      >
        <div className="status_modal_wrapper">
          <div className="status_button_box">
            {["Pending", "Approved"].includes(normalizedStatus) ? (
              [
                ...(status === "Pending"
                  ? ["Approved", "Cancelled", "Dispatched"]
                  : ["Cancelled", "Dispatched"]),
              ].map((v) => {
                return (
                  <Button
                    key={v}
                    className={`${getColor(v)}`}
                    onClick={() => setSelected(v)}
                  >
                    {selected === v && (
                      <MdOutlineDone
                        size={18}
                        style={{ marginRight: "5px", marginBottom: "-4px" }}
                      />
                    )}
                    <span>{v}</span>
                  </Button>
                );
              })
            ) : (
              <span></span>
            )}
          </div>
          <div className="status_modal_footer">
            <Button className="buttonOutlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="button"
              type="primary"
              loading={isLoading}
              onClick={handleUpdate}
              disabled={!selected}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default Status;
