import React, { useEffect, useState } from "react";
import { Input, Table, Tooltip } from "antd";

import { useAppSelector } from "../../../store/hooks";

import DeleteModal from "../../../components/Modals/DeleteModal";
import CreateSchedule from "../../../components/Modals/CreateSchedule";

import { firstLetterCap } from "../../../utils";
import { ScheduleType } from "../../../store/interfaces/Schedule";
import { getSchedule } from "../../../store/services/schedule";
import moment from "moment";

const { Search } = Input;

const Schedule: React.FC = () => {
  const { isLoading, schedules } = useAppSelector((state) => state.schedule);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, [page]);

  const getData = async () => {
    await getSchedule();
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (t: string) => <p>{firstLetterCap(t)}</p>,
    },
    {
      title: "Users",
      dataIndex: "users",
      key: "users",
      render: (_: string, obj: ScheduleType) => {
        const displayUsers = obj?.users.slice(0, 2);
        const otherUsers = obj?.users.slice(2);

        return (
          <Tooltip
            placement="top"
            title={otherUsers?.map((v) => `${v?.email}`).join(", ")}
          >
            <>
              {displayUsers?.map((v, index) => (
                <span key={index}>
                  {v?.email}
                  {index < displayUsers?.length - 1 && ", "}
                </span>
              ))}
              {otherUsers?.length > 0 && (
                <span>
                  <strong>, and {otherUsers?.length} more...</strong>
                </span>
              )}
              {!!obj?.users && <span>-</span>}
            </>
          </Tooltip>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (t: string) => (
        <p>{t === "oneTime" ? "One Time" : firstLetterCap(t)}</p>
      ),
    },
    {
      title: "Time Period",
      dataIndex: "timePeriod",
      key: "timePeriod",
      width: 180,
      render: (t: string, obj: ScheduleType) => {
        const getValue = () => {
          const value = obj.timePeriodValue;
          const type = obj.timePeriodType;
          switch (obj.type) {
            case "recursive":
              return `Repeat Every ${value ? value : "-"} ${type ? type : ""}`;
            case "oneTime": //@ts-ignore
              const formattedDate = obj.timePeriod.split(" ")[0]; 
              //@ts-ignore
              const formattedTime = obj.timePeriod.split(" ")[1]; 
              console.log("🚀 ~ getValue ~ formattedTime:", formattedTime)
              
              const firstPart = moment(formattedDate).format("MMM DD, YYYY");
              
              console.log("🚀 ~ getValue ~ firstPart:", firstPart)
              return firstPart + " " + moment(formattedTime,"HH:mm:ss").format("hh:mm");
            default:
              return "-";
          }
        };
        return <p>{getValue()}</p>;
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (t: Date) => <p>{t ? moment(t).format("MMM DD, YYYY") : "-"}</p>,
      width: 130,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (t: Date) => <p>{t ? moment(t).format("MMM DD, YYYY") : "-"}</p>,
      width: 130,
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, obj: ScheduleType) => (
        <div className="icon_box">
          <CreateSchedule isEdit editObj={obj} getData={getData} />
          <DeleteModal type={"schedule"} id={obj?._id} getData={getData} />
        </div>
      ),
    },
  ];

  const filteredSchedules = schedules?.filter((v) =>
    v?.name?.toLowerCase()?.includes(search?.toLowerCase())
  );

  return (
    <div className="user_container">
      <header>
        <h1>Schedule</h1>
        <div>
          <CreateSchedule getData={getData} />
        </div>
      </header>

      <div className="search_input_box">
        <Search
          placeholder="Search by name..."
          enterButton
          allowClear
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="user_table_container category_table">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={filteredSchedules}
          rowKey={(record) => record._id}
          scroll={{ x: 1100 }}
          pagination={{
            current: page,
            total: schedules?.length,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Schedule;
