import React, { useState, ChangeEvent } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Checkbox,
  TimePicker,
} from "antd";
import { TbEdit } from "react-icons/tb";
import {
  CreateScheduleOnFinish,
  CreateScheduleType,
  ScheduleType,
} from "../../store/interfaces/Schedule";
import { createSchedule, updateSchedule } from "../../store/services/schedule";
import { useAppSelector } from "../../store/hooks";
import dayjs from "dayjs";
import { getLabels } from "../../store/services/label";
import { getUsersForSchedule } from "../../store/services/user";

const { RangePicker } = DatePicker;

interface Props {
  isEdit?: boolean;
  editObj?: ScheduleType;
  getData: () => void;
}

interface yearArr {
  year: string;
  points: number;
}

const CreateSchedule: React.FC<Props> = ({ isEdit, editObj, getData }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [anniversary, setAnniversary] = useState<Boolean>(false);
  const [anniversaryArray, setAnniversaryArray] = useState<yearArr[]>([]);
  const [loading, setLoading] = useState<string>("");
  const [datePeriod, setDatePeriod] = useState<string>("");
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const { scheduleUsers } = useAppSelector((state) => state.user);

  const _recursive: "recursive" = "recursive";
  const _oneTime: "oneTime" = "oneTime";
  const _birthday: "birthday" = "birthday";
  const _anniversary: "anniversary" = "anniversary";

  const getUsers = async () => {
    !userLoading && setUserLoading(true);
    await getUsersForSchedule();
    setUserLoading(false);
  };

  const handleShowModal = () => {
    setIsModalOpen(true);
    if (isEdit) {
      form.setFieldsValue({
        name: editObj?.name,
        users: editObj?.users?.map((v) => v?._id),
        type: editObj?.type,
        recursive:
          editObj?.type === _recursive ? editObj?.timePeriod : undefined,
        oneTime:                                       //@ts-ignore
          editObj?.type === _oneTime ? dayjs(editObj?.timePeriod?.split(" ")[0]) : undefined,
          timeSet:                                       //@ts-ignore
          editObj?.type === _oneTime ? dayjs(editObj?.timePeriod?.split(" ")[1], "HH:mm:ss") : undefined,
        points: editObj?.points,
        active: editObj?.active,
        timePeriodType: editObj?.timePeriodType,
        timePeriodValue: editObj?.timePeriodValue,
        recursiveDateRange: editObj?.startDate
          ? [dayjs(editObj?.startDate), dayjs(editObj?.endDate)]
          : undefined,
        //@ts-ignore
        year:
          editObj?.type === _anniversary
            ? editObj?.anniversary?.map((a: any) => {
                let label;
                if (a.year === "0") label = "3 months";
                else if (a.year === "0.5") label = "6 months";
                else
                  label = `${a.year}${
                    a.year === "1"
                      ? "st year"
                      : a.year === "2"
                      ? "nd year"
                      : a.year === "3"
                      ? "rd year"
                      : "th year"
                  }`;
                return {
                  label,
                  value: a.year,
                  points: a.points,
                };
              })
            : undefined,
        //@ts-ignore
        default: editObj?.type === _anniversary ? editObj?.points : 0,
      });

      if (editObj?.type === "anniversary") {
        //@ts-ignore
        setAnniversaryArray(editObj?.anniversary);
        setAnniversary(true);
      }
      editObj?.type && setDatePeriod(editObj?.type);
    }
    getUsers();
  };

  const handleCancel = () => {
    form.resetFields();
    setAnniversary(false);
    setAnniversaryArray([]);
    setIsModalOpen(false);
    setDatePeriod("");
  };

  const optionYear = [
    { label: "3 months", value: 0 },
    { label: "6 months", value: 0.5 },
    { label: "1st year", value: 1 },
    { label: "2nd year", value: 2 },
    { label: "3rd year", value: 3 },
    { label: "4th year", value: 4 },
    { label: "5th year", value: 5 },
    { label: "6th year", value: 6 },
    { label: "7th year", value: 7 },
    { label: "8th year", value: 8 },
    { label: "9th year", value: 9 },
    { label: "10th year", value: 10 },
    { label: "11th year", value: 11 },
    { label: "12th year", value: 12 },
  ];

  const onFinish = async (values: CreateScheduleOnFinish) => {
    const getDays = () => {
      const daysInYear = 365;
      const daysInMonth = 30;
      const daysInWeek = 7;
      const daysInDay = 1;

      if (values.type === _recursive) {
        switch (values.timePeriodType) {
          case "year":
            return Number(values.timePeriodValue) * daysInYear;
          case "month":
            return Number(values.timePeriodValue) * daysInMonth;
          case "week":
            return Number(values.timePeriodValue) * daysInWeek;
          case "day":
            return Number(values.timePeriodValue) * daysInDay;
          default:
            return 0;
        }
      }
      return 0;
    };

    const dateRange = values?.recursiveDateRange;
    // const anniversaryArrayWithDefault = values?.default ?
    //   anniversaryArray.map((item) => ({
    //     ...item,
    //     points: item.points,
    //   }))
    //   : anniversaryArray;
    //@ts-ignoreS
console.log(values.timeSet?.format("HH:mm:ss"))
    const obj = {
      _id: editObj?._id ? editObj?._id : null,
      name: values?.name,
      users: values?.users,
      type: values?.type,
      timePeriod:
        values?.type === _recursive
          ? values?.recursive
          : values?.type === _oneTime    //@ts-ignore
          ? values?.oneTime?.toISOString() + " " + values.timeSet?.format("HH:mm:ss")
          : undefined,
      points: values?.default
        ? Number(values?.default)
        : Number(values?.points),
      active: values?.active,
      timePeriodType: values?.timePeriodType || undefined,
      timePeriodValue: Number(values?.timePeriodValue) || undefined,
      days: getDays(),
      startDate: dateRange ? dayjs(dateRange[0])?.toISOString() : undefined,
      endDate: dateRange ? dayjs(dateRange[1])?.toISOString() : undefined,
      anniversary: values.type === _anniversary ? anniversaryArray : undefined,
    };
    isEdit ? await _update(obj) : await _create(obj);
    return;
  };

  const _create = async (payload: CreateScheduleType) => {
    setLoading("submit");
    const res = await createSchedule(payload);
    if (res) {
      handleCancel();
      getData();
    }
    setLoading("");
  };

  const _update = async (payload: CreateScheduleType) => {
    setLoading("submit");
    const res = await updateSchedule(payload);
    if (res) {
      handleCancel();
      getData();
    }
    setLoading("");
  };

  const SettingData = (e: any) => {
    if (e.target.value === "anniversary") {
      setAnniversary(true);
    } else {
      setAnniversary(false);
      setAnniversaryArray([]);
    }
  };

  // const getItem = (selectedYears: number[]) => {
  //   const updatedAnniversaryArray = selectedYears.map(year => {
  //     const existingItem = anniversaryArray.find(item => item.year === year.toString());
  //     return {
  //       year: year.toString(),
  //       points: existingItem ? existingItem.points : 0,
  //     };
  //   });

  //   console.log("existingItem",updatedAnniversaryArray);

  //   setAnniversaryArray(updatedAnniversaryArray);
  // };

  const getItem = (selectedYears: number[]) => {
    const newYears = selectedYears.filter((year) => {
      return !anniversaryArray.some((item) => item.year === year.toString());
    });

    if (newYears.length > 0) {
      const updatedAnniversaryArray = [...anniversaryArray];

      newYears.forEach((year) => {
        const existingItem = anniversaryArray.find(
          (item) => item.year === year.toString()
        );
        let label;

        if (year === 0) {
          label = "0 months";
        } else if (year === 0.5) {
          label = "6 months";
        } else {
          label = `${year} year${year > 1 ? "s" : ""}`;
        }

        updatedAnniversaryArray.push({
          year: year.toString(),
          //@ts-ignore
          label: label,
          points: existingItem ? existingItem.points : 0,
        });
      });

      setAnniversaryArray(updatedAnniversaryArray);
    }
  };

  const editValue = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedAnniversaryArray = [...anniversaryArray];
    updatedAnniversaryArray[index] = {
      ...updatedAnniversaryArray[index],
      points: Number(e.target.value),
    };
    setAnniversaryArray(updatedAnniversaryArray);
  };

  const getYearName = (yearValue: number) => {
    const option = optionYear.find((item) => item.value === yearValue);
    return option ? option.label : yearValue.toString();
  };

  return (
    <>
      {isEdit ? (
        <TbEdit
          onClick={handleShowModal}
          className="icon"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Button onClick={handleShowModal}>Create Schedule</Button>
      )}
      <Modal
        forceRender
        title={`${isEdit ? "Update Schedule " : "Create Schedule"}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={(e) => {
            e.type && setDatePeriod(e.type);
          }}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Name (Required)" />
              </Form.Item>
            </Col>
            {(datePeriod === _recursive || datePeriod === _oneTime) && (
              <Col xs={24}>
                <Form.Item
                  label="Users"
                  name="users"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    loading={userLoading}
                    optionFilterProp="children"
                    filterOption={(
                      input: string,
                      option?: { label: string; value: string }
                    ) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size={"large"}
                    maxTagCount={"responsive"}
                    placeholder="Select"
                    className="users_select_box"
                    options={scheduleUsers?.map((v) => {
                      return {
                        label: v?.email,
                        value: v?._id,
                      };
                    })}
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24}>
              <Form.Item
                label="Schedule Type"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Radio.Group onChange={(e) => SettingData(e)}>
                  <Radio value={_recursive}>Recursive</Radio>
                  <Radio value={_oneTime}>One Time</Radio>
                  <Radio value={_birthday}>Birthday</Radio>
                  <Radio value={_anniversary}>Anniversary</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {datePeriod === _recursive && (
              <>
                <Col xs={24}>
                  <Form.Item
                    label="Time Period"
                    name="recursive"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      optionFilterProp="children"
                      filterOption={(
                        input: string,
                        option?: { label: string; value: string }
                      ) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      size={"large"}
                      maxTagCount={"responsive"}
                      placeholder="Select"
                      className="users_select_box"
                      options={[
                        { value: "Daily", label: "Daily" },
                        { value: "Weekly", label: "Weekly" },
                        { value: "Monthly", label: "Monthly" },
                        { value: "Yearly", label: "Yearly" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Date Range"
                    name="recursiveDateRange"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <RangePicker
                      className="w-full"
                      format={"YYYY-MM-DD"}
                      allowClear={true}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {datePeriod === _oneTime && (<>
              <Col xs={24}>
                <Form.Item
                  label="Time Period"
                  name="oneTime"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <DatePicker className="w-full" format={"YYYY-MM-DD"} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Time"
                  name="timeSet"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                 <TimePicker format="HH:mm"  />
                </Form.Item>
              </Col>
              </> )}
            {datePeriod === _recursive && (
              <>
                <Col xs={12}>
                  <Form.Item
                    label="Time Period Type"
                    name="timePeriodType"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      options={[
                        { label: "Day", value: "day" },
                        { label: "Week", value: "week" },
                        { label: "Month", value: "month" },
                        { label: "Year", value: "year" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Time Period Value"
                    name="timePeriodValue"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Value" />
                  </Form.Item>
                </Col>
              </>
            )}
            {datePeriod === _anniversary && (
              <Col xs={24}>
                <Form.Item
                  label="Year"
                  name="year"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    optionFilterProp="children"
                    filterOption={(
                      input: string,
                      option?: { label: string; value: string }
                    ) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    size={"large"}
                    maxTagCount={"responsive"}
                    placeholder="Select"
                    className="users_select_box"
                    onChange={getItem}
                    options={optionYear?.map((v: any) => {
                      let label;
                      if (v.value === 0) {
                        label = "3 months";
                      } else if (v.value === 0.5) {
                        label = "6 months";
                      } else {
                        label = `${v.value} year${v.value > 1 ? "s" : ""}`;
                      }

                      return {
                        label: label,
                        value: v.value,
                      };
                    })}
                  />
                </Form.Item>
              </Col>
            )}
            {anniversaryArray.length > 0 && anniversary && (
              <Col xs={24}>
                {anniversaryArray.map((data, index) => (
                  <Row gutter={[20, 5]} key={index} className="anniversary-row">
                    <Col xs={12}>
                      <Form.Item label="Year">
                        <Input
                          value={getYearName(Number(data.year))}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item label="Points">
                        <Input
                          onChange={(e) => editValue(e, index)}
                          value={data.points}
                          type="number"
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
            )}
            {(datePeriod === _recursive ||
              datePeriod === _oneTime ||
              datePeriod === _birthday) && (
              <Col xs={24}>
                <Form.Item
                  label="Points"
                  name="points"
                  rules={[
                    {
                      required: true,
                      message: "Required",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Points (Required)"
                    min={0}
                  />
                </Form.Item>
              </Col>
            )}
            {anniversary && (
              <Col xs={24}>
                <Form.Item
                  label="Default"
                  name="default"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Default Points (Required)"
                    inputMode={"numeric"}
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24}>
              <Form.Item
                label="Active"
                name="active"
                valuePropName="checked"
                initialValue={true}
              >
                <Checkbox>Active</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} className="flex items-center justify-end">
              <Form.Item className="mb-0">
                <Button onClick={handleCancel} className="btn btn-light">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading === "submit"}
                  style={{ marginLeft: "5px" }}
                >
                  {isEdit ? "Update Schedule" : "Create Schedule"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateSchedule;
