import { attachToken, privateAPI } from "../../config/constants";
import { notification } from "antd";
import store from "..";

import { CreateProductPayload, GetProductPayload } from "../interfaces/Product";
import {
  setAllProducts,
  setLoading,
  setProducts,
} from "../slices/productSlice";

const handleError = (error: any) => {
  console.log(error?.response?.data?.message);
  notification.error({
    message: error?.response?.data?.message || "Server error",
  });
};

const Created = "Product Created Successfully";
const Updated = "Product Updated Successfully";
const Deleted = "Product Deleted Successfully";
const Uploaded = "Product Csv Uploaded Successfully";

export const createProduct = async (payload: CreateProductPayload) => {
  try {
    attachToken();
    const response = await privateAPI.post("/product/create", payload);
    notification.success({
      message: response?.data?.data?.message || Created,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const uploadSingleImage = async (payload: any) => {
  try {
    attachToken();
    const response = await privateAPI.post("/product/upload-image", payload);
    return response?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getProducts = async (payload: GetProductPayload) => {
  try {
    attachToken();
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/product/search", payload);
    if (response) {
      store.dispatch(setProducts(response?.data?.data));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getAllProducts = async () => {
  try {
    attachToken();
    const response = await privateAPI.get("/product/get-all-products");
    if (response) {
      store.dispatch(setAllProducts(response?.data?.data?.products));
    }
    return response?.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateProuct = async (payload: CreateProductPayload) => {
  try {
    attachToken();
    const response = await privateAPI.put("/product/update", payload);
    notification.success({
      message: response?.data?.data?.message || Updated,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteProduct = async (id: string) => {
  try {
    attachToken();
    const response = await privateAPI.delete(`/product/delete?productId=${id}`);
    notification.success({
      message: response?.data?.data?.message || Deleted,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const uploadProductCsv = async (payload: any) => {
  try {
    attachToken();
    const response = await privateAPI.post(`/product/uploadproducts`, payload);
    notification.success({
      message: response?.data?.data?.message || Uploaded,
    });
    return response;
  } catch (error: any) {
    handleError(error);
  }
};

export const getProductCsv = async () => {
  try {
    attachToken();
    const response = await privateAPI.get(`/product/export`);
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
