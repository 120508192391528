import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Modal,
  Button,
  notification,
  Checkbox,
  Spin,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import {
  createProduct,
  updateProuct,
  uploadSingleImage,
} from "../../../store/services/product";
import {
  ProductType,
  ProductVariationsType,
} from "../../../store/interfaces/Product";

import { PlusOutlined } from "@ant-design/icons";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { HiOutlineTrash } from "react-icons/hi";
import { FaRegEye } from "react-icons/fa";

import AddVariations from "../../../components/Modals/AddVariations";

import {
  getCategoriesByType,
  getSubCategories,
} from "../../../store/services/category";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { setSubCategories } from "../../../store/slices/categorySlice";
const { TextArea } = Input;

const CreateProduct: React.FC = () => {
  const { typeCategories, subCategories } = useAppSelector(
    (state) => state.category
  );

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<string>("submit");
  const [variations, setVariations] = useState<Array<ProductVariationsType>>(
    []
  );
  const [fileList, setFileList] = useState<String[]>([]);
  const [isVirtual, setIsVirtual] = useState<boolean>(false);
  const [virtualLinkList, setVirtualLinkList] = useState<String[]>([]);
  const [linkError, setLinkError] = useState<boolean>(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state?.isEdit) {
     state?.product?.imagesUrl && setFileList(state?.product?.imagesUrl);
      state?.product?.variations && setVariations(state?.product?.variations);
      state?.product?.urls && setVirtualLinkList(state?.product?.urls);
      form.setFieldsValue({
        name: state?.product?.name,
        description: state?.product?.description,
        category: state?.product?.categoryid,
        subCategory: state?.product?.subCategory,
        productType: state?.product?.productType,
        stock:
          state?.product?.productType === "virtual"
            ? state?.product?.urls?.length
            : state?.product?.stock,
        points: state?.product?.points,
        cost: state?.product?.cost,
        // urls: state?.product?.urls,
        isActive: state?.product?.isActive,
        deliveryFee: state?.product?.deliveryFee,
      });
      (async () => {
        if (state?.product?.category) {
          await _getSubCategories(state?.product?.categoryid);
        }
      })();
      state?.product?.productType === "virtual"
        ? setIsVirtual(true)
        : setIsVirtual(false);
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    _getCategoriesByType();

    return () => {
      dispatch(setSubCategories([]));
    };
    // eslint-disable-next-line
  }, []);

  const _getCategoriesByType = async () => {
    setLoading("category");
    await getCategoriesByType({ type: "category" });
    setLoading("");
  };

  const _getSubCategories = async (id: string) => {
    setLoading("subcategory");
    await getSubCategories({ categoryId: id });
    setLoading("");
  };

  const handleVirtualProductLink = (url: string) => {
   if (url?.trim() && !virtualLinkList?.includes(url)) {
      let temp = [...virtualLinkList];
      temp.push(url);
      setVirtualLinkList(temp);
      form.setFieldsValue({
        urls: "",
        stock: String(temp?.length),
      });
      setLinkError(false);
    }
  };

  const handleRemoveVirtualProduct = (index: number) => {
    let temp = [...virtualLinkList];
    temp.splice(index, 1);
    setVirtualLinkList([...temp]);
    form.setFieldsValue({
      stock: String([...temp]?.length),
    });
  };

  
  const onFinish = async (values: ProductType) => {
    const name = values?.name ? values?.name : state?.product?.name || "";
    const description = values?.description
      ? values?.description
      : state?.product?.description || "";
    const stock = values?.stock ? values?.stock : state?.product?.stock || "";
    const points = values?.points
      ? values?.points
      : state?.product?.points || 0;
    const cost = values?.cost
      ? values?.cost
      : state?.product?.cost || 0;
    const category = values?.category
      ? values?.category
      : state?.product?.category || "";
    const subCategory = values?.subCategory
      ? values?.subCategory
      : state?.product?.subCategory || "";
    const productType = values?.productType
      ? values?.productType
      : state?.product?.productType || "";
    const urls = virtualLinkList ? virtualLinkList : state?.urls || [];
    // const isActive = values?.isActive
    //   ? values?.isActive
    //   : state?.product?.isActive;
    const deliveryFee =
      productType === "virtual"
        ? 0
        : values?.deliveryFee
        ? values?.deliveryFee
        : state?.product?.deliveryFee;

    const obj = {
      _id: state?.product?._id ? state?.product?._id : null,
      name,
      description,
      stock: Number(stock),
      points: Number(points),
      cost: Number(cost),
      category,
      subCategory,
      productType,
      variations: isVirtual ? [] : variations,
      imagesUrl: fileList,
      urls,
      isActive: values?.isActive,
      deliveryFee,
    };
    if (fileList?.length <= 0) {
      notification.error({
        message: "Please add product image.",
      });
    } else if (isVirtual &&  virtualLinkList?.length <= 0) {
      setLinkError(true);
    } else {
      if (virtualLinkList?.length > 0) {
        setLinkError(false);
      }
      state?.isEdit ? _updateProduct(obj) : _createProduct(obj);
    }
  };

  // Create and Update Product
  const _createProduct = async (payload: any) => {
    setLoading("submit");
    const res = await createProduct(payload);
    if (res) {
      form.resetFields();
      setVariations([]);
      setFileList([]);
      navigate("/product");
    }
    setLoading("");
  };
  const _updateProduct = async (payload: any) => {
    setLoading("submit");
    const res = await updateProuct(payload);
    if (res) {
      form.resetFields();
      setVariations([]);
      setFileList([]);
      navigate("/product");
    }
    setLoading("");
  };

  //   Variation Function
  const handleVariations = (obj: ProductVariationsType) => {
    const temp = [...variations];
    temp.push(obj);
    setVariations(temp);
  };
  
  const handleUpdateVariations = (
    obj: ProductVariationsType,
    index: number
  ) => {
    const temp = [...variations];
    temp.splice(index, 1, obj);
    setVariations(temp);
  };

  const handleRemoveVariation = (index: number) => {
    const temp = [...variations];
    temp.splice(index, 1);
    setVariations(temp);
  };

  // Upload Image Function
  const handleUpload = async (file: any) => {
    setLoading("image")
    let formData = new FormData();
    formData.append("storecsv", file);
    const responseUrl: string = await uploadSingleImage(formData);
    const temp = [...fileList];
    temp.push(responseUrl);
    setFileList(temp);
    setLoading('')
  };
  const handleRemove = (index: number) => {
    const temp = [...fileList];
    temp.splice(index, 1);
    setFileList(temp);
  };

  return (
    <div className="create_user_container">
      <header>
        <div>
          <IoArrowBack
            size={20}
            className="cursor"
            onClick={() => navigate(-1)}
          />
          <h1>{state?.isEdit ? "Update Product" : "Create Product"}</h1>
        </div>
      </header>

      <div className="form_container">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={(e) => {
            e.productType === "virtual" && setIsVirtual(true);
            e.productType === "virtual" &&
              setVirtualLinkList(state?.product?.urls??[]);
            e.productType === "physical" && setIsVirtual(false);
            e.productType === "physical" && setVirtualLinkList([]);
            e.category && _getSubCategories(e.category);
          }}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <h2>Product Information</h2>
            </Col>
            <Col xs={24}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <UploadImages
                  handleUpload={handleUpload}
                  handleRemove={handleRemove}
                  fileList={fileList}
                  loading={loading}
                />
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"productType"}
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  placeholder="Select Type"
                  options={[
                    { value: "physical", label: "Physical" },
                    { value: "virtual", label: "Virtual" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"name"}
                label="Product Name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Product Name (Required)" maxLength={70} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name={"description"}
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                {/* <Input placeholder="Description (Required)" /> */}
                <TextArea
                  className="description_textarea"
                  placeholder="Description (Required)"
                  allowClear
                  maxLength={1500}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"category"}
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  loading={loading === "category"}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(
                    input: string,
                    option?: { label: string; value: string }
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={typeCategories?.map((v) => {
                    return {
                      value: v?._id,
                      label: v?.name,
                    };
                  })}
                  placeholder="Select Category"
                  onChange={() => form.setFieldValue("subCategory", undefined)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"subCategory"}
                label="Sub Category"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  loading={loading === "subcategory"}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(
                    input: string,
                    option?: { label: string; value: string }
                  ) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={subCategories?.map((v) => {
                    return {
                      value: v?._id,
                      label: v?.name,
                    };
                  })}
                  placeholder="Select Sub Category"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                name={"stock"}
                label="Stock"
                rules={[
                  {
                    required: false,
                    message: "Required",
                  },
                ]}
              >
                <Input
                  min={1}
                  disabled={isVirtual}
                  type="number"
                  placeholder="Stock"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"points"}
                label="Points"
                rules={[
                  {
                    required: isVirtual ? true : false,
                    message: "Required",
                  },
                ]}
              >
                <Input min={1} type="number" placeholder="Points" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={"cost"}
                label="cost"
                rules={[
                  {
                    required: isVirtual ? true : false,
                    message: "Required",
                  },
                ]}
              >
                <Input min={1} type="number" placeholder="cost" />
              </Form.Item>
            </Col>
            {!isVirtual && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name={"deliveryFee"}
                  label="Delivery Fee"
                  rules={[
                    {
                      required: false,
                      message: "Required",
                    },
                  ]}
                >
                  <Input
                    min={1}
                    disabled={isVirtual}
                    type="number"
                    placeholder="Delivery Fee"
                  />
                </Form.Item>
              </Col>
            )}
            {state?.isEdit && (
              <Col xs={24} sm={12}>
                <Form.Item
                  name={"isActive"}
                  label="Status"
                  valuePropName="checked"
                  rules={[
                    {
                      required: false,
                      message: "Required",
                    },
                  ]}
                >
                  <Checkbox
                    onChange={(e) => {
                      const checked = e.target.checked;
                      form.setFieldsValue({ isActive: checked });
                    }}
                  >
                    Active
                  </Checkbox>
                </Form.Item>
              </Col>
            )}
            {/* {isVirtual && (
              <> */}
                <Col xs={20}>
                  <Form.Item name={"urls"} label="Add Link" >
                    <Input placeholder="Link" />
                  </Form.Item>
                </Col>
                <Col
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type={"primary"}
                    style={{
                      width: "100%",
                      height: "40px",
                    }}
                    onClick={() => {
                      handleVirtualProductLink(form.getFieldValue("urls"));
                    }}
                  >
                    Add
                  </Button>
                </Col>
                {linkError && (
                  <Col xs={24}>
                    <p style={{ color: "red" }}>Required</p>
                  </Col>
                )}
              {/* </>
            )} */}
            { virtualLinkList?.length > 0 && (
              <Col xs={24} style={{ marginTop: "10px" }}>
                <div className="link_tags_box">
                  {virtualLinkList?.map((link, index) => {
                    return (
                      <div key={index} className="link_tags">
                        <p>{link}</p>
                        <IoClose
                          size={18}
                          onClick={() => handleRemoveVirtualProduct(index)}
                          className="cursor"
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
            )}
            {!isVirtual && (
              <>
                <Col xs={24}>
                  <AddVariations
                    setVariationsData={handleVariations}
                    updateVariationsData={() => {}}
                    allVariation={variations}
                  />
                </Col>
                <Col xs={24}>
                  {variations?.length > 0 &&
                    variations?.map((v, i) => {
                      return (
                        <div key={i} className="main_variation_box">
                          <header>
                            <p>
                              Size: <strong>{v?.size}</strong>
                            </p>
                            <div>
                              <AddVariations
                                isDuplicate={true}
                                index={i}
                                editItem={v}
                                setVariationsData={handleVariations}
                                updateVariationsData={handleUpdateVariations}
                                allVariation={variations}
                              />
                              <AddVariations
                                isEdit={true}
                                index={i}
                                editItem={v}
                                setVariationsData={handleVariations}
                                updateVariationsData={handleUpdateVariations}
                                allVariation={variations}
                              />
                              <HiOutlineTrash
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemoveVariation(i)}
                              />
                            </div>
                          </header>
                          <div className="color_box_container">
                            <div className="main_color_box_header">
                              <p>Color</p>
                              <p>Stock</p>
                              <p>Points</p>
                            </div>
                            {v?.colors?.map((x, i) => {
                              return (
                                <div className="main_color_box" key={i}>
                                  <div className="color_box">
                                    <div
                                      style={{ backgroundColor: `${x?.hex}` }}
                                    />
                                    <p>{x?.hex}</p>
                                    <p>{x?.color}</p>
                                  </div>
                                  <div className="stock_box">
                                    <p>{x?.stock}</p>
                                  </div>
                                  <div className="points_box">
                                    <p>{x?.points}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </Col>
              </>
            )}
            <Col xs={24} style={{ marginTop: "20px" }}>
              <Form.Item>
                <Button
                  loading={loading === "submit"}
                  type="primary"
                  htmlType="submit"
                >
                  {state?.isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CreateProduct;

interface UploadImagesType {
  handleUpload: (file: any) => void;
  handleRemove: (index: number) => void;
  fileList: String[];
  loading:any;
}
const UploadImages = ({
  handleUpload,
  handleRemove,
  fileList,
  loading
}: UploadImagesType) => {

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewType, setPreviewType] = useState<"image" | "video">("image");
  
  
  const handleCancel = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };
  const handlePreview = async (url: string) => {
    setPreviewImage(url);
    setPreviewType(url.toLowerCase().endsWith('.mp4') ? 'video' : 'image');
    setPreviewOpen(true);
  };

  const isVideo = (url: string) => url.toLowerCase().endsWith('.mp4');

  return (
    <div>
      <div className="main-upload-box">
        {fileList?.map((v, i) => {
          return (
            <div
              className="show-image"
              style={{ backgroundImage: `url(${v})` }}
              key={i}
            >
              {isVideo(String(v)) ? (
                <video 
                  src={String(v)} 
                   className="show-image"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                <div style={{ backgroundImage: `url(${v})`, width: '100%', height: '100%',backgroundSize:'contain' }} />
              )}
        
              <div className="show-hover">
                <FaRegEye
                  style={{ color: "#fff" }}
                  className="cursor"
                  onClick={() => handlePreview(String(v))}
                />
                <HiOutlineTrash
                  style={{ color: "#fff" }}
                  className="cursor"
                  onClick={() => handleRemove(i)}
                />
              </div>
            </div>
          );
          
        })}

        {loading === "image" ?<div style={{display:'flex',justifyContent:"center",alignItems:'center'}}> <Spin /> </div> : <>

        {fileList?.length <= 6 && (
          <div className="upload-btn-wrapper">
            <div className="upload-btn">
              <PlusOutlined />
              <p>Upload</p>
            </div>
            <input
              type="file"
              name="myfile"
              accept="image/png, image/jpg, image/jpeg, video/mp4"
              onChange={(e: any) => handleUpload(e?.target?.files[0])}
            />
          </div>
        )}
        </>}
      </div>
      <div style={{ display: "block", marginBottom: "10px" }}>
        <p>Required (You can only upload up to 6 images)</p>
      </div>

      <Modal
        open={previewOpen}
        title={"Preview"}
        footer={null}
        onCancel={handleCancel}
        centered
      >
       {previewType === 'video' ? (
          <video 
            controls
            style={{ width: "100%" }}
            src={previewImage}
          />
        ) : (
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        )}
      </Modal>
    </div>
  );
};
