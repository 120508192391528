import React, { useEffect, useState } from "react";
import { Spin, notification } from "antd";


interface Props {
  data: any;
  loading?: boolean;
}

const ProductCard: React.FC<Props> = ({ data }) => {
 
  const [
    itemCount,
    // setItemCount
  ] = useState(1);
  const [selectedColor, setSelectedColor] = useState<any>();
  const [selectedSize, setSelectedSize] = useState<any>();
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    setSelectedColor(data?.variations?.[0]?.colors?.[0]);
    setSelectedSize(data?.variations?.[0] || {});
  }, [data]);

  useEffect(() => {
    setSelectedColor(selectedSize?.colors?.[0] || {});
  }, [selectedSize]);


  const isVideoFile = (url: string) => {
    return url?.toLowerCase().endsWith(".mp4");
  };

  return (
    <div className="product_card_wrapper" style={{ height: "100%" }}>
      {localLoading && (
        <div className="card_loading">
          <Spin />
        </div>
      )}
      <div className="product_card">
        <div
          className="product_image"
          style={{ backgroundImage: `url(${data?.imagesUrl?.[0]})` }}
        >
          {isVideoFile(data?.imagesUrl?.[0]) && (
            <video
              autoPlay
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <source src={data?.imagesUrl?.[0]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <div className="OutOfStock">
            {selectedColor?.stock && selectedColor?.stock < 1 ? (
              <p>Out of Stock</p>
            ) : null}

            {!selectedColor?.stock && data?.stock < 1 ? (
              <p>Out of Stock</p>
            ) : null}
          </div>
        </div>

        <div className="product_detail">
          <p
            className="product_name"
          >
            {data?.name}
          </p>

          <div className="color_and_size_box">
            {selectedSize?.colors?.length > 0 && (
              <div>
                <p>Color</p>
                <div>
                  {selectedSize?.colors?.slice(0, 6)?.map((item: any) => (
                    <div
                      key={item?._id}
                      className="color_div"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        background: item?.hex,
                        border:
                          selectedColor?._id === item?._id
                            ? "2px solid var(--black)"
                            : "none",
                      }}
                      onClick={() => setSelectedColor(item)}
                    />
                  ))}
                </div>
              </div>
            )}
            <div>
              {selectedSize?.size === "Fixed" ||
                ((data?.variations ? data?.variations?.length > 0 : false) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0px",
                    }}
                  >
                    <p>Size</p>
                    <div style={{ display: "flex", gap: "3px" }}>
                      {data?.variations?.map((item: any, i: number) => (
                        <p
                          key={item?._id}
                          style={{
                            fontWeight:
                              item?._id === selectedSize?._id ? 800 : 400,
                            cursor: "pointer",
                            color: "var(--black)",
                          }}
                          onClick={() => setSelectedSize(item)}
                        >
                          {item?.size || "-"}
                        </p>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
