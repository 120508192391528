import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "..";



const initialState: any = {
  carouselLoading: true,
  total: 0,
  carousel: [],
};

export const carouselSlice = createSlice({
  name: "carousel",
  initialState,
  reducers: {
    setCarouselLoading: (state, action) => {
      state.carouselLoading = action.payload;
    },
    setCarousel: (state, action) => {
      state.carousel = action.payload.carousel;
    },
  },
});

export const { setCarouselLoading, setCarousel } = carouselSlice.actions;

export const getCarousels = (state: RootState) => state.carousel;

export default carouselSlice.reducer;
