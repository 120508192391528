import React, { useState } from "react";

import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Input,
  ColorPicker,
  Checkbox,
} from "antd";

import { HiOutlineDuplicate, HiOutlineTrash } from "react-icons/hi";
import { TbEdit } from "react-icons/tb";

import {
  ProductColorType,
  ProductVariationsType,
} from "../../store/interfaces/Product";
import { url } from "inspector";

const emptyVariation = {
  hex: "#000000",
  stock: "",
  points: "",
};
const emptyVariations = {
  size: "",
  colors: [],

};

interface Props {
  setVariationsData: (obj: ProductVariationsType) => void;
  isEdit?: boolean;
  isDuplicate?: boolean;
  index?: number;
  editItem?: ProductVariationsType;
  updateVariationsData: (obj: ProductVariationsType, index: number) => void;
  allVariation?: Array<ProductVariationsType>;
}

const AddVariations: React.FC<Props> = ({
  setVariationsData,
  index,
  isEdit,
  isDuplicate,
  editItem,
  updateVariationsData,
  allVariation,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [size, setSize] = useState<string>("");
  const [variation, setVariation] = useState<ProductColorType>(emptyVariation);
  const [variations, setVariations] =
    useState<ProductVariationsType>(emptyVariations);

  const showModal = () => {
    if ((isEdit || isDuplicate ) && editItem) {
      let temp = JSON.parse(JSON.stringify(editItem))
      isDuplicate && delete temp.size
      temp.colors = isDuplicate && temp.colors?.length ? temp.colors.map((c:any)=>{
        return{
          hex:c.hex,
          stock:c.stock,
          points:c.points,
        }
      }):temp.colors
      setVariations(temp);
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setSize("");
    setVariation(emptyVariation);
    setVariations({
      size: "",
      colors: [],
    });
    setIsModalOpen(false);
  };

  const handleSave = () => {
    if (size?.trim() !== "" && variations?.colors?.length > 0) {
      let tempobj = { ...variations, size }
      delete tempobj._id
      console.log("🚀 ~ handleUpdate ~ tempobj:", tempobj)
      setVariationsData(tempobj);
      handleCancel();
    }
  };

  const handleUpdate = () => {
    if (size?.trim() !== "" && variations?.colors?.length > 0) {
   
      updateVariationsData({ ...variations, size }, Number(index));
      handleCancel();
    }
  };

  const handleAdd = () => {
    if (
      variation?.hex?.trim() !== "" &&
      variation?.stock &&
      variation?.points
    ) {
      const temp = { ...variations };
      temp.colors.push(variation);
      setVariations(temp);
      setVariation(emptyVariation);
    }
  };

  const handleDelete = (index: number) => {
    const temp = { ...variations };
    temp.colors.splice(index, 1);
    setVariations(temp);
  };

  const isFixed = () => {
    return allVariation?.some((v) => v?.size === "Fixed");
  };

  const showCheckbox = () => {
    if ((isEdit || isDuplicate ) && allVariation && allVariation?.length < 2) {
      return true;
    } else {
      if (allVariation) {
        if (allVariation.length === 1 && allVariation[0]?.size === "Fixed") {
          return true;
        } else if (allVariation.length === 0) {
          return true;
        }
      }
    }
  };

  return (
    <>
      {
      isEdit ? (
        <TbEdit onClick={showModal} style={{ cursor: "pointer" }} />
      ) : 
      isDuplicate ? (
        <HiOutlineDuplicate onClick={showModal} style={{ cursor: "pointer" }} />
      ) : (
        <h2
          onClick={() => !isFixed() && showModal()}
          className="add_variation_text"
        >
          + Add Variations
        </h2>
      )}
      <Modal
        forceRender
        title={"Add Variations"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="add_variation_modal"
        centered
      >
        <Divider />
        <Row gutter={[10, 10]}>
          {showCheckbox() ? (
            <Col xs={24}>
              <Checkbox
                checked={size === "Fixed"}
                onChange={(e) =>
                  e.target.checked ? setSize("Fixed") : setSize("")
                }
              >
                Fixed
              </Checkbox>
            </Col>
          ) : null}
          <Col xs={24}>
            <p>Size</p>
            <Input
              placeholder="Size (S, M, L, Xl, XXL )"
              onChange={(e) => setSize(e.target.value)}
              value={size}
              disabled={size === "Fixed"}
              // maxLength={10}
            />
          </Col>
          <Col xs={6}>
            <p>Color</p>
            <ColorPicker
              showText
              format={"hex"}
              size="middle"
              value={variation?.hex}
              onChange={(_: any, value: string) =>
                setVariation((pre: ProductColorType) => {
                  return { ...pre, hex: value };
                })
              }
            />
          </Col>
          <Col xs={6}>
            <p>Color</p>
            <Input  
              type="text"
              placeholder="Color"
              value={variation?.color}
              onChange={(e) =>
                setVariation((pre: ProductColorType) => {
                  return { ...pre, color: e.target.value };
                })
              }
            />
          </Col>
          <Col xs={6}>
            <p>Stock</p>
            <Input
              min={1}
              type="number"
              placeholder="Stock"
              value={variation?.stock}
              onChange={(e) =>
                setVariation((pre: ProductColorType) => {
                  return { ...pre, stock: e.target.value };
                })
              }
            />
          </Col>
          <Col xs={6}>
            <p>Point</p>
            <Input
              min={1}
              type="number"
              placeholder="Points"
              value={variation?.points}
              onChange={(e) =>
                setVariation((pre: ProductColorType) => {
                  return { ...pre, points: e.target.value };
                })
              }
            />
          </Col>
          <Col xs={4}>
            <div className="add_button_box">
              <Button onClick={handleAdd}>Add</Button>
            </div>
          </Col>
          <Col xs={24}>
            <div
              style={{
                height: "1px",
                backgroundColor: "var(--borderLight)",
                opacity: 0.5,
              }}
            />
          </Col>
          <Col xs={24}>
            {variations?.colors?.length > 0 &&
              variations?.colors?.map((v, i) => {
                return (
                  <Row
                    key={i}
                    gutter={[10, 10]}
                    style={{ marginBottom: "10px" }}
                  >
                    <Col xs={6}>
                      <div className="color_box">
                        <div style={{ backgroundColor: `${v?.hex}` }} />
                        <p>{v?.hex}</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="stock_box">
                        <p>{v?.color}</p>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="stock_box">
                        <p>{v?.stock}</p>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <div className="points_box">
                        <p>{v?.points}</p>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <Button
                        className="trash_btn"
                        onClick={() => handleDelete(i)}
                        icon={<HiOutlineTrash style={{ color: "red" }} />}
                      ></Button>
                    </Col>
                  </Row>
                );
              })}
          </Col>
          <Col xs={24}>
            {isEdit ? (
              <Button type="primary" onClick={handleUpdate}>
                Update
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={handleSave}
                disabled={variations?.colors?.length <= 0}
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default AddVariations;
