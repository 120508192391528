import React, { useEffect, useState } from "react";
import {
  Button,
  //  Button,
  Input,
  Modal,
  Select,
  Table,
} from "antd";

import { useAppSelector } from "../../../store/hooks";
import { OrderProductType, OrderType } from "../../../store/interfaces/Order";
import { getOrders } from "../../../store/services/order";

// import DeleteModal from "../../../components/Modals/DeleteModal";
// import ViewCartDetails from "../../../components/Modals/ViewCartDetails";

// import { firstLetterCap } from "../../../utils";
import {
  ProductColorType,
  ProductVariationsType,
} from "../../../store/interfaces/Product";
import Status from "../../../components/Modals/Status";
// import { useNavigate } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import moment from "moment";

const { Search } = Input;

const Order: React.FC = () => {
  const { isLoading, orders, total } = useAppSelector((state) => state.order);
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [sortOption, setSortOption] = useState<string>("All");

  // const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(search, sortOption);
    // eslint-disable-next-line
  }, [page, sortOption]);

  const getData = async (search?: string, sortOption?: string) => {
    await getOrders({
      search: search || "",
      page,
      perPage,
      orderStatus: sortOption === "All" ? "" : sortOption,
    });
  };

  const columns = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: 70,
      render: (_: string, obj: OrderType) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              obj?.user?.imageUrl
                ? obj?.user?.imageUrl
                : require("../../../assets/images/user-icon.jpg")
            })`,
          }}
        />
      ),
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, obj: OrderType) => (
        <p>
          {obj?.user?.firstName} {obj?.user?.lastName}
        </p>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (_: string, obj: OrderType) => <p>{obj?.user?.phoneNumber}</p>,
    },
    {
      title: "Number of Product",
      dataIndex: "numberOfProduct",
      key: "numberOfProduct",
      render: (_: string, obj: OrderType) => <p>{obj?.products?.length}</p>,
    },
    {
      title: "Total Points",
      dataIndex: "total",
      key: "total",
      render: (_: string, obj: OrderType) => (
        <p>{obj?.priceInformation?.total}</p>
      ),
    },
    {
      title: "Total Cost",
      dataIndex: "cost",
      key: "cost",
      render: (_: string, obj: OrderType) => {
        // const totalCost = obj?.products?.reduce((sum, product) => sum + (product?.product?.cost || 0), 0);
        return <p>{obj?.priceInformation?.cost ?? 0}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: string, obj: OrderType) => (
        <div className="icon_box">
          <Status id={obj?._id} status={obj?.orderStatus} />
        </div>
      ),
    },
    {
      title: "Order History",
      dataIndex: "orderHistory",
      key: "orderHistory",
      render: (_: string, obj: any) => {
        return <OrderHistoryModal history={obj?.orderHistory || []} />;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_: string, obj: OrderType) => (
        <div className="icon_box">
          <p>{moment(obj?.updatedAt).format("MM-DD-YYYY")}</p>
        </div>
      ),
    },
  ];

  const columns2 = [
    {
      title: " ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: 70,
      render: (_: string, obj: OrderProductType) => (
        <div
          className="users_image_box"
          style={{
            backgroundImage: `url(${
              obj?.product?.imagesUrl?.length > 0
                ? obj?.product?.imagesUrl?.[0]
                : require("../../../assets/images/box.png")
            })`,
          }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, obj: OrderProductType) => <p>{obj?.product?.name}</p>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: string, obj: OrderProductType) => (
        <p>{obj?.product?.description}</p>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_: string, obj: OrderProductType) => <p>{obj?.quantity}</p>,
    },
    {
      title: "Total Points",
      dataIndex: "points",
      key: "points",
      render: (_: string, obj: OrderProductType) => <p>{obj?.points || 0}</p>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (_: string, obj: OrderProductType) => <p>{obj?.cost || 0}</p>,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (_: string, obj: OrderProductType) => {
        const isSize =
          obj?.product?.variations &&
          obj?.product?.variations?.find(
            (v: ProductVariationsType) => v?._id === obj?.size
          );
        return <p>{isSize?.size || "-"}</p>;
      },
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (_: string, obj: OrderProductType) => {
        const selectedVariation = obj?.product?.variations?.find(
          (variation: ProductVariationsType) => variation._id === obj?.size
        );

        const isColor =
          selectedVariation &&
          selectedVariation?.colors?.find(
            (v: ProductColorType) => v?._id === obj?.color
          );

        return (
          <>
            {isColor ? (
              <>
                <p
                  style={{
                    width: "30px",
                    height: "20px",
                    backgroundColor: isColor?.hex,
                    borderRadius: "4px",
                  }}
                ></p>{" "}
                <p>{isColor.color ?? ""}</p>
              </>
            ) : (
              <p>-</p>
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: string, obj: any) => {
        if (!obj?.product?.urls || obj?.product?.urls.length === 0) {
          return <p>{"-"}</p>;
        }

        return (
          <div>
            {obj.product.urls.map((url: string, index: number) => (
              <a
                key={index}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: "10px", color: "blue" }}
              >
                <FaExternalLinkAlt />
              </a>
            ))}
          </div>
        );
      },
    },
  ];

  const selectOrders = async (e: any, k: any) => {
    setPage(1);
    setSortOption(k.value);
  };

  return (
    <div className="user_container">
      <header>
        <h1>Orders</h1>
      </header>
      <div className="filter_order">
        <div className="search_input_box">
          <Search
            placeholder="Search by name or email..."
            className="search_input"
            onSearch={(value) => getData(value)}
            enterButton
            allowClear
            value={search}
            onChange={(e) => {
              const value = e.target.value;
              setSearch(value);
              setPage(1);
              value === "" && getData("");
            }}
          />
        </div>
        <div className="status_select">
          <p style={{ width: "50px", textAlign: "center" }}>Status :</p>
          <Select
            className="select_status"
            value={sortOption}
            style={{ width: 150, height: 45 }}
            disabled={isLoading}
            onChange={(e: any, k: any) => selectOrders(e, k)}
            options={[
              {
                key: "cancelled",
                value: "Cancelled",
                label: "Cancelled",
              },
              {
                key: "completed",
                value: "Completed",
                label: "Completed",
              },
              {
                key: "Pending",
                value: "Pending",
                label: "Pending",
              },
              {
                key: "dispatched",
                value: "Dispatched",
                label: "Dispatched",
              },
            ]}
          />
          <Button
            type="primary"
            className="btn_clear"
            style={{ height: 40 }}
            onClick={() => {
              setSortOption("All");
              setSearch("");
              getData();
            }}
          >
            Clear
          </Button>
        </div>
      </div>

      <div className="user_table_container">
        <Table
          loading={isLoading}
          rowKey={(record, i) => record._id}
          columns={columns}
          dataSource={orders}
          expandable={{
            expandedRowRender: (record) => (
              <div
                className="user_table_container expanded_table"
                style={{ marginTop: "0px" }}
              >
                <Table
                  rowKey={(obj, i) => obj?.product?.name + i}
                  columns={columns2}
                  dataSource={record.products}
                  pagination={false}
                />
              </div>
            ),
            rowExpandable: (record) => record.products.length > 0,
          }}
          scroll={{ x: 1000 }}
          pagination={{
            current: page,
            total,
            pageSize: perPage,
            onChange: (val) => {
              setPage(val);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Order;

const OrderHistoryModal = ({ history }: any) => {
  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);


  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (t: string) => <p>{moment(t).format("MMM DD, YYYY | hh:mm A")}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (t: string, obj:any) =>  <Status id={obj?._id} status={obj?.status} isClick={false}/>,
    },
  ];
  return (
    <>
      <p style={{ cursor: "pointer", color: "blue" }} onClick={openModal}>
        View
      </p>
      <Modal
        forceRender
        title="Order History"
        open={open}
        onCancel={closeModal}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Table
          columns={columns}
          dataSource={history}
          // scroll={{ x: 1000 }}
          // pagination={{
          //   current: page,
          //   total,
          //   pageSize: perPage,
          //   onChange: (val) => {
          //     setPage(val);
          //   },
          // }}
        />
      </Modal>
    </>
  );
};
